import React from "react";
import Divider from "@mui/material-next/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "../Dash.css";
import Namebutton from "../components/Namebutton";
import Dissbutton from "../components/Dissbutton";
import Carousel from "../components/Carousel";
import Barchart from "../chart/Barchart";
import Linechart from "../chart/Linechart";
import { Line } from "rc-progress";


const styles = {
  dfirst_outerContainer: {
    display: "flex",
    gap: 2,
    "@media (min-width:475px) and (max-width:693px)": {
      justifyContent: "center",
    },
    "@media (min-width:694px) and (max-width:919px)": {
      justifyContent: "start",
    },
    "@media (min-width:345px) and (max-width:475px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  dfirst_container: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    "@media (min-width:694px) and (max-width:919px)": {
      width: "74%",
      flexWrap: "wrap",
      justifyContent: "start",
      marginLeft: "16px !important",
    },
    "@media (min-width:475px) and (max-width:693px)": {
      width: "98%",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    "@media (min-width:345px) and (max-width:475px)": {
      flexDirection: "column",
    },
  },
  dcalender_container: {
    display: "flex",
    marginLeft: 2,

    "@media (min-width:345px) and (max-width:693px)": {
      marginTop: 2,
      marginLeft: 0,
    },
    "@media (min-width:920px) and (max-width:1375px)": {
      marginLeft: 0,
    },
  },
  graphContainer: {
    display: "flex",
    flexDirection: "row",

    marginTop: 3,
    "@media (min-width:345px) and (max-width:693px)": {
      flexDirection: "column",
      alignItems: "center",
      marginTop: 1,
    },
    "@media (min-width:694px) and (max-width:1375px)": {
      flexDirection: "column",
    },
  },
  task_container: {
    display: "flex",

    overflow: "auto",
    scrollbarWidth: "thin",
    "@media (min-width:345px) and (max-width:693px)": {
      marginTop: 3,
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    "@media (min-width:694px) and (max-width:1375px)": {
      marginTop: 3,
      flexDirection: "column",
      alignItems: "start",
      width: "100%",
    },
  },
};

const DashboardC = () => {
  return (
    <Box component="main" sx={{ flex: 1, p: 2, width: "95%" }}>
      <Grid container sx={styles.dfirst_outerContainer}>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" sx={styles.dfirst_container}>
            <div className="smallcard w-[210px] h-[170px]">
              <img className="iconCircle" src="/assignment.png" alt="" />
              <Typography className="title" variant="subtitle1">
                Assignment
              </Typography>
              <CardContent>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography className="number" gutterBottom variant="h5">
                    400
                  </Typography>
                </div>
                <Divider
                  sx={{ color: "#173767", marginBottom: 0.5, marginTop: 1 }}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Namebutton />
                </div>
              </CardContent>
            </div>

            <div className="smallcard w-[210px] h-[170px]">
              <img className="iconCircle" src="/yettoallocate.png" alt="" />

              <Typography
                className="title"
                gutterBottom
                variant="subtitle1"
                component="div"
              >
                Yet to Allocate
              </Typography>
              <CardContent>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="h5" gutterBottom className="number">
                    100
                  </Typography>
                </div>
                <Divider sx={{ color: "#173767", marginTop: 1 }} />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Line percent={60} strokeWidth={5} strokeColor="#173767" />
                  <Typography variant="subtitle2" style={{ marginLeft: "5px" }}>
                    60%
                  </Typography>
                </div>
              </CardContent>
            </div>

            <div className="smallcard w-[210px] h-[170px]">
              <img className="iconCircle" src="/inprogress.png" alt="" />

              <Typography
                className="title"
                gutterBottom
                variant="subtitle1"
                component="div"
              >
                InProgress
              </Typography>
              <CardContent>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="h5" gutterBottom className="number">
                    100
                  </Typography>
                </div>
                <Divider sx={{ color: "#173767", marginTop: 1 }} />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Line percent={60} strokeWidth={5} strokeColor="#173767" />
                  <Typography variant="subtitle2" style={{ marginLeft: "5px" }}>
                    60%
                  </Typography>
                </div>
              </CardContent>
            </div>

            <div className="smallcard w-[210px] h-[170px]">
              <img className="iconCircle" src="/inreview.png" alt="" />

              <Typography
                className="title"
                gutterBottom
                variant="subtitle1"
                component="div"
              >
                InReview
              </Typography>
              <CardContent>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="h5" gutterBottom className="number">
                    100
                  </Typography>
                </div>
                <Divider sx={{ color: "#173767", marginTop: 1 }} />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Line percent={60} strokeWidth={5} strokeColor="#173767" />
                  <Typography variant="subtitle2" style={{ marginLeft: "5px" }}>
                    60%
                  </Typography>
                </div>
              </CardContent>
            </div>
          </Stack>

          <Stack direction="row" sx={styles.dfirst_container}>
            <div className="smallcard w-[210px] h-[170px]">
              <img className="iconCircle" src="/assignment.png" alt="" />
              <Typography
                className="title"
                gutterBottom
                variant="subtitle1"
                component="div"
              >
                Dissertation
              </Typography>
              <CardContent>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="h5" gutterBottom className="number">
                    400
                  </Typography>
                </div>
                <Divider
                  sx={{ color: "#173767", marginBottom: 0.5, marginTop: 1 }}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Dissbutton />
                </div>
              </CardContent>
            </div>

            <div className="smallcard w-[210px] h-[170px]">
              <img className="iconCircle" src="/yettoallocate.png" alt="" />

              <Typography
                className="title"
                gutterBottom
                variant="subtitle1"
                component="div"
              >
                Yet to Allocate
              </Typography>
              <CardContent>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="h5" gutterBottom className="number">
                    100
                  </Typography>
                </div>
                <Divider sx={{ color: "#173767", marginTop: 1 }} />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Line percent={60} strokeWidth={5} strokeColor="#173767" />
                  <Typography variant="subtitle2" style={{ marginLeft: "5px" }}>
                    60%
                  </Typography>
                </div>
              </CardContent>
            </div>

            <div className="smallcard w-[210px] h-[170px]">
              <img className="iconCircle" src="/inprogress.png" alt="" />

              <Typography
                className="title"
                gutterBottom
                variant="subtitle1"
                component="div"
              >
                InProgress
              </Typography>
              <CardContent>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="h5" gutterBottom className="number">
                    100
                  </Typography>
                </div>
                <Divider sx={{ color: "#173767", marginTop: 1 }} />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Line percent={60} strokeWidth={5} strokeColor="#173767" />
                  <Typography variant="subtitle2" style={{ marginLeft: "5px" }}>
                    60%
                  </Typography>
                </div>
              </CardContent>
            </div>

            <div className="smallcard w-[210px] h-[170px]">
              <img className="iconCircle" src="/inreview.png" alt="" />

              <Typography
                className="title"
                gutterBottom
                variant="subtitle1"
                component="div"
              >
                InReview
              </Typography>
              <CardContent>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="h5" gutterBottom className="number">
                    100
                  </Typography>
                </div>
                <Divider sx={{ color: "#173767", marginTop: 1 }} />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Line percent={60} strokeWidth={5} strokeColor="#173767" />
                  <Typography variant="subtitle2" style={{ marginLeft: "5px" }}>
                    60%
                  </Typography>
                </div>
              </CardContent>
            </div>
          </Stack>
        </Stack>

        <Stack sx={styles.dcalender_container}>
          <div className="datecalendars w-[330px] h-[385px] ">
            <CardContent>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar />
              </LocalizationProvider>
            </CardContent>
          </div>
        </Stack>
      </Grid>

      <Stack sx={styles.graphContainer}>
        <Grid item xs={8} sx={styles.task_container}>
          <Card className="card1" sx={{ width: 410, height: 200 }}>
            <Typography className="title" sx={{ textAlign: "center" }}>
              Today due Date
            </Typography>
            <CardContent>
              <Carousel />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8} sx={styles.task_container}>
          <Card
            className="card1"
            sx={{
              width: 800,
              height: 200,
              overflow: "auto",
              scrollbarWidth: "thin",
            }}
          >
            <CardContent className="title">
              <Linechart />
            </CardContent>
          </Card>
        </Grid>
      </Stack>

      <Grid item xs={12} width="100%" overflow="auto" sx={{ marginTop: 4 }}>
        <Card className="card1" sx={{ width: "100%" }}>
          <CardContent className="title">
            <Barchart />
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );
};

export default DashboardC;
