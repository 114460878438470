import React, { useCallback, useEffect} from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Login from "./page/Login";
import ForgotPassword from "./page/Forgotpassword";
import SetPassword from "./page/SetPassword";
import { useAppContext } from "./AppContext";
import Manager from "./Layouts/Manager";
import Admin from "./Layouts/Admin";
import Error400Page from "./Layouts/Error400Page";
import NotificationSound from "./assets/sound/level-up-191997.mp3";

const ProtectedRoute = ({ element, allowedRoles }) => {
  const { userInfo } = useAppContext();
  return allowedRoles.includes(userInfo?.role) ? (
    element
  ) : (
    <Navigate to="/" replace />
  );
};

const RoleRedirect = () => {
  const { userInfo, socket, handleLeaveApproval } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo?.role) {
      navigate(`/${userInfo.role}`, { replace: true });
      if (userInfo.role === "manager" || userInfo.role === "admin") {
        userInfo.employee.forEach((val) => {
          socket.emit("join room", val.id);
        });
        handleLeaveApproval();
      } else if (userInfo.role === "employee") {
        socket.emit("join room", userInfo.id);
      }
    }
  }, [userInfo, socket, navigate]);

  return null;
};

function App() {
  const {
    socket,
    audioplayer,
    setReceivedMessage,
    handleLeaveApproval,
    setToggle,
    leaveNotification,
   
  } = useAppContext();
  // const silentAudioRef = useRef(null);

  const handleReceiveMessage = useCallback((data) => {
    setReceivedMessage(data.message);
    if (audioplayer?.current) {
      audioplayer?.current?.play();
    }
    setToggle((val) => !val);
    handleLeaveApproval();
    // console.log("received message")
  }, [audioplayer]);

  useEffect(() => {
    socket.on("receive message", handleReceiveMessage);
    return () => {
      socket.off("receive message", handleReceiveMessage);
    };
  }, [socket,leaveNotification]);

  // useEffect(() => {
  //   handleLeaveApproval();
  // }, []);


  return (
    <BrowserRouter>
      {audioplayer && <audio src={NotificationSound} ref={audioplayer} />}
      {/* <audio src={SilentAudio} ref={silentAudioRef} /> */}
      <RoleRedirect />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/setpassword/:userId/:token" element={<SetPassword />} />
        <Route
          path="/manager"
          element={
            <ProtectedRoute element={<Manager />} allowedRoles={["manager"]} />
          }
        />
        <Route
          path="/employee"
          element={
            <ProtectedRoute
              element={<Manager />}
              allowedRoles={["employee"]}
            />
          }
        />
        <Route
          path="/freelancer"
          element={
            <ProtectedRoute
              element={<Manager />}
              allowedRoles={["freelancer"]}
            />
          }
        />
        <Route
          path="/hr"
          element={<ProtectedRoute element={<Manager />} allowedRoles={["hr"]} />}
        />
        <Route
          path="/admin"
          element={
            <ProtectedRoute element={<Admin />} allowedRoles={["admin"]} />
          }
        />
        <Route path="/error400" element={<Error400Page />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
