import axios from "axios";
import { useState,useEffect } from "react";
import { useParams} from 'react-router-dom';
import { useNavigate} from 'react-router-dom';
import ErrorPopup from "../Popup/ErrorPopup";
import LoginImage from "../images/setpassword.jpg";
import Logo from "../images/KRZLogo.png";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const SetPassword = () => {
  const [password,setPassword]=useState();
  const [confirmPassword,setConfirmPassword]=useState();
  const [show,setShow]=useState(false);
  const [message,setMessage]=useState();
const [isLoading,setIsLoading]=useState(false);
  const navigate = useNavigate();
  const {userId,token}=useParams();
  const onResetClick = async(e) => {
    e.preventDefault();
    try{
      setIsLoading(true);
      const response= await axios.post(`${process.env.REACT_APP_baseurl}/password-reset/${userId}/${token}`,{password:password,confirmPassword:confirmPassword});
      if(response?.data){
        toast.success(response?.data?.msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setPassword("");
        setConfirmPassword("");
        navigate.go(1);
      }
      
    }catch(err){
      setMessage(err.response.data.msg);
      setShow(true);
    }finally{
      setIsLoading(false);
      const timeoutId = setTimeout(() => {
        navigate("/")
      }, 6000);
      return () => clearTimeout(timeoutId);
    }
  };

  useEffect(() =>{
   
    const timeoutId = setTimeout(() => {
      setShow(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  
},[show,message])

//   const handleBacktoLogin = () => {
//   navigate('/')
//        console.log('Back to login clicked');
// };
// const backtoLogin = {
//     color: '#173767',
// };


return (
  
    <div className="wrapper">
       <style>
        {`
          .logo{
          width:225px;
          }
          .subtext{
            font-size:16px;
            text-align:center;

          }
        
          @media (min-width: 769px) and (max-width: 1024px) {
          .logo{
          object-fit:contain;
          margin-left:-75px;
          }
            .subtext{
              font-size:14px;
            }
          }

          @media (max-width: 769px){
          .logo{
          object-fit:contain;
          margin-left:-75px;
          }
            .subtext{
              font-size:13px;
            }
          }
        
         
        `}
      </style>
          <img src={Logo} className="logo" alt="logo"/> 
     
        
        <form  className="password-container" onSubmit={onResetClick}>
          <img  alt="" src="/group-5.svg"  />
          <h4 >Set New Password</h4>
          <p className="subtext">Must be at least 6 characters</p>
          {show && <ErrorPopup message={message} />}
          <div className="form-groupss ">
            <label>Password</label><br/>
            <input
              placeholder="********"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              
            />
          </div>
          <div className="form-groupss ">
            <label>Confirm Password</label>
            <br/>
            <input
              placeholder="********"
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
             
            />
          </div>
          
            <button type="submit">
              {isLoading ? "Loading..." : "Reset Password"}
            </button>
          
        </form>
      
      <div className="image-container">
        <img src={LoginImage} alt="Login"  />
      </div>
    </div>
  
);
};

export default SetPassword;
