import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import "../components/Sidebarmenu.css";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Select } from "antd";
import axios from "axios";
import "../CSS/Admin.css";
import ErrorPopup from "../Popup/ErrorPopup";
import ConfirmPrompt from "../Popup/ConfirmPrompt";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setGlobalToken } from "../components/Authorization";
import Switcher from "../components/Switcher";
import EditButton from "../components/EditButton";
import { useAppContext } from "../AppContext";
import Calendar from "../components/Calendar";
import TablePagination from "@mui/material/TablePagination";
import Leave from "../components/Leave";

const Admin = () => {
  const { content } = useAppContext();
  const [userDetails, setUserDetails] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "",
    manager: "",
    managerId: "",
    phoneNumber: "",
    dob: "",
    designation: "",
    department: "",
  });
  // const navigate = useNavigate();
  const [createUser, setCreateUser] = useState(false);
  const [id, setId] = useState();
  const [users, setUsers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [toConfirm, setToConfirm] = useState(false);
  const [value, setValue] = useState("");
  // const [ispasswordFilled,setPasswordIsFilled]= useState(false);
  const [isroleFilled, setRoleIsFilled] = useState(false);
  const [ismanagerFilled, setManagerIsFilled] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [switcherState, setSwitcherState] = useState(true);
  const [empId, setEmpId] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { userInfo,leaveNotification } = useAppContext();

  const columns = [
    {
      id: "employeeId",
      label: "Emp Id",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "dob",
      label: "DOB",
    },
    {
      id: "designation",
      label: "Designation",
    },
    {
      id: "department",
      label: "Department",
    },
    {
      id: "role",
      label: "Role",
    },
    {
      id: "manager",
      label: "Manager",
    },
    {
      id: "edit",
      label: "Edit",
    },
    {
      id: "activity",
      label: "Activity",
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCancel = () => {
    setCreateUser(false);
    setRoleIsFilled(false);
    setManagerIsFilled(false);
    setUserDetails({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      role: "",
      manager: "",
      phoneNumber: "",
      dob: "",
    });
    setValue("");
  };

  // const handleDelete = async (id) => {
  //   setToConfirm(true);
  //   setValue('delete')
  //   setId(id);
  // };

  const handleSubmit = async (e) => {
    // console.log("called")
    e.preventDefault();
    if (
      !userDetails.id ||
      !userDetails.email ||
      !userDetails.password ||
      !userDetails.role ||
      !userDetails.manager ||
      phoneNumberError
    ) {
      !userDetails.role ? setRoleIsFilled(true) : setRoleIsFilled(false);
      !userDetails.manager
        ? setManagerIsFilled(true)
        : setManagerIsFilled(false);
      toast("Kindly fill required fields", {
        type: "warning",
        position: "top-right",
        autoClose: 3000,
      });
      console.log("Error", userDetails);
      return;
    } else {
      setRoleIsFilled(false);
      setManagerIsFilled(false);
    }
    setToConfirm(true);
    // console.log("confirmmm");
  };

  const handlePhoneNumber = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, ""); // Replace non-numeric characters
    setUserDetails({ ...userDetails, phoneNumber: newValue });
    if (newValue.length < 10) {
      setPhoneNumberError("Input must be 10 numberic!");
    } else {
      setPhoneNumberError(""); // Clear error message if length is within limit
    }
  };

  // const generateEmpId = (num, members, role) => {
  //   const date = new Date();
  //   const year = date.getFullYear().toString();
  //   const cYear = year.slice(-2);

  //   // console.log("lengthhhh", members.length)
  //   if (members[num]?.id) {
  //     const empDataId = members[num]?.id;
  //     const pYear = empDataId.substr(4, 2);
  //     const id = empDataId.substr(-3);
  //     const unit = parseInt(id.substr(-1));
  //     const tencode = id.charCodeAt(1);
  //     const ten = tencode - 65;
  //     const thousandcode = id.charCodeAt(0);
  //     const thousand = thousandcode - 65;
  //     const number = thousand * 260 + ten * 10 + unit + 1;
  //     // console.log("....", number);
  //     num = number;

  //     if (cYear !== pYear) {
  //       num = 0;
  //     }
  //   } else {
  //     num = 0;
  //   }

  //   const unitDigit = num % 10;
  //   const tens = parseInt(num / 10);
  //   const thousands = parseInt(num / 260);

  //   let char1 = "A";
  //   let char2 = "A";

  //   if (thousands > 0 && thousands < 26) {
  //     const code = char2.charCodeAt(0);
  //     const incrementCode = code + thousands;
  //     char2 = String.fromCharCode(incrementCode);
  //   }

  //   if (tens < 26 && thousands === 0) {
  //     const code = char1.charCodeAt(0);
  //     const incrementCode = code + tens;
  //     char1 = String.fromCharCode(incrementCode);
  //   }

  //   const empChar = role === "freelancer" ? "F" : "E";
  //   const Id = `${empChar}${cYear}${char2}${char1}${unitDigit}`;

  //   if (value !== "edit") {
  //     setEmpId(Id);
  //   }
   
  // };

 

  const getAllUsers =  async() => {
    try {
      setGlobalToken();
      const response = await axios.get(`${process.env.REACT_APP_baseurl}/admin/users`);
      if (response?.data.length !== 0) {
        const allUsers = response?.data.filter((user) => user.role !== "admin");
        setUsers(allUsers);
        const roleByManager = response.data.filter(
          (user) =>
            (user.role === "admin" || user.role === "manager") &&
            user.firstName !== userDetails.firstName
        );
        setManagers(roleByManager);
        // employeeIds(allUsers.length);
      }
    } catch (err) {
      console.log("users..........", err?.response);
      // if(err.response.status===401 || err.reponse.status===403){
      //   navigate("/error400");
      // }
    }
  }

  // const employeeIds = (role) => {
  //   if (role !== "freelancer") {
  //     const allUsers = users.filter((user) => user.role !== "freelancer");
  //     // generateEmpId(allUsers.length - 1, allUsers, role);
  //   } else {
  //     const allUsers = users.filter((user) => user.role === "freelancer");
  //     // console.log("---------freelancer", allUsers);
  //     generateEmpId(allUsers.length - 1, allUsers, role);
  //   }
  // };

  useEffect(() => {
    getAllUsers();
  }, [createUser, toConfirm, switcherState, value]);

  useEffect(() => {
    setUserDetails({ ...userDetails, id: empId });
    // console.log("detilaaa", userInfo);
  }, [empId,userInfo]);

  useEffect(() => {
    if (userDetails.id === "") {
      setUserDetails({ ...userDetails, id: empId });
    } else if (value !== "edit") {
      setUserDetails({
        id: empId,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        role: "",
        manager: "",
        phoneNumber: "",
        dob: "",
      });
    }
    // employeeIds(users);
  }, [createUser, value,empId]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        position: "relative",
      }}
    >
      <Sidebar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Navbar />
        {/* <div
          style={{
            display:"flex",
            flexDirection:"column",
            width: "100%",
            height: "100vh",
            overflow: "auto",
            padding: "5px 2px 20px 2px",
            position: "relative",
          }}
        > */}
        {
          content === "dashboard" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* <Box sx={{ height: 30 }} /> */}
              {!createUser ? (
                <Button
                  variant="contained"
                  style={{
                    width: "fit-content",
                    marginLeft: "auto",
                    cursor: "pointer",
                    textTransform: "none",
                    marginRight: "0.8rem",
                    background: "#173767",
                    marginTop: "0.7rem",
                    fontSize: "16px",
                  }}
                  onClick={() => setCreateUser(true)}
                >
                  Create User
                </Button>
              ) : (
                <></>
              )}
              <Box
                sx={{
                  width: "100%",
                  height: "100%",

                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {createUser ? (
                  <form
                    onSubmit={(e) => handleSubmit(e)}
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    <h3 style={{ fontSize: "20px", textAlign: "center" }}>
                      {value === "edit" ? "Edit" : "New Acquisition"}
                    </h3>
                    <div className="form-group">
                      <label htmlFor="empid">
                        Employee ID<span className="text-[#FF0000]"> *</span>
                      </label>
                      <input
                        id="empid"
                        type="text"
                        value={userDetails?.id}
                        // disabled
                        onChange={(e)=>setUserDetails({...userDetails,id:e?.target?.value})}
                        // style={{ opacity: 0.6 }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="firstName">First Name</label>
                      <input
                        id="firstName"
                        type="text"
                        value={userDetails.firstName}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            firstName: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        id="lastName"
                        type="text"
                        value={userDetails.lastName}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            lastName: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">
                        Email<span className="text-[#FF0000]"> *</span>
                      </label>
                      <input
                        id="email"
                        type="email"
                        value={userDetails.email}
                        required
                        disabled={value === "edit" ? true : false}
                        style={{ opacity: value === "edit" ? 0.3 : 1 }}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">
                        Password<span className="text-[#FF0000]"> *</span>
                      </label>
                      <input
                        id="password"
                        type="password"
                        required
                        value={userDetails.password}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            password: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="role">
                        Role<span className="text-[#FF0000]"> *</span>
                      </label>
                      <Select
                        style={{
                          border: isroleFilled ? "2px solid red" : "default",
                        }}
                        className="w-full h-10 outline-none leading-none bg-transparent border-2 border-[#B3B3B3] rounded"
                        placeholder="Select Role"
                        value={userDetails?.role}
                        id="role"
                        onChange={(value) => {
                          // employeeIds(value);
                          setUserDetails({ ...userDetails, role: value });
                        }}
                      >
                        <Select.Option value="manager">Manager</Select.Option>
                        <Select.Option value="employee">Employee</Select.Option>
                        {/* <Select.Option value="freelancer">
                          FreeLancer
                        </Select.Option> */}
                        <Select.Option value="hr">Human Resource</Select.Option>
                        <Select.Option value="admin">Admin</Select.Option>
                      </Select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="manager">
                        Manager<span className="text-[#FF0000]"> *</span>
                      </label>
                      {managers.length !== 0 ? (
                        <Select
                          style={{
                            border: ismanagerFilled
                              ? "2px solid red"
                              : "default",
                            textTransform: "capitalize",
                          }}
                          className="w-full outline-none leading-none bg-transparent border-2 border-[#B3B3B3] rounded h-10"
                          placeholder="Select Manager"
                          value={userDetails?.manager}
                          id="manager"
                          onChange={(value) => {
                            const selectedManager = managers.find(
                              (manager) => manager._id === value
                            );

                            setUserDetails({
                              ...userDetails,
                              manager: selectedManager.firstName,
                              managerId: selectedManager._id,
                            });
                            setManagerIsFilled(false);
                          }}
                        >
                          {managers.map((option) => (
                            <Select.Option
                              key={option._id}
                              value={option._id}
                              style={{ textTransform: "capitalize" }}
                            >
                              {option.firstName}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : (
                        <input
                          id="manager"
                          type="text"
                          required
                          value={userDetails.manager}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              manager: e.target.value,
                            });
                          }}
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="phoneNumber">Phone Number</label>
                      <input
                        id="phoneNumber"
                        type="text"
                        maxLength={10}
                        value={userDetails.phoneNumber}
                        onChange={(e) => handlePhoneNumber(e)}
                      />
                    </div>
                    {phoneNumberError && (
                      <div
                        style={{
                          color: "red",
                          marginLeft: "auto",
                          marginRight: 5,
                        }}
                      >
                        {phoneNumberError}
                      </div>
                    )}
                    <div className="form-group">
                      <label htmlFor="dob">Date of Birth</label>
                      <input
                        type="date"
                        id="onboarding"
                        name="onboarding"
                        value={userDetails.dob}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            dob: e.target.value,
                          })
                        }
                        placeholder="Select date"
                        style={{
                          width: "200px",
                          margin: "5px",
                          outline: "none",
                          border: "1px solid #B3B3B3",
                          borderRadius: "4px",
                          padding: "2px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="designation">
                        Designation
                        {/* <span className="text-[#FF0000]"> *</span> */}
                      </label>
                      <input
                        id="designation"
                        type="text"
                        value={userDetails.designation}
                        // required
                        // disabled={value==='edit'? true: false}
                        //  style={{opacity: value==="edit"? 0.3:1}}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            designation: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="department">
                        Department
                        {/* <span className="text-[#FF0000]"> *</span> */}
                      </label>
                      <input
                        id="Dapartment"
                        type="text"
                        value={userDetails.department}
                        // required
                        //   disabled={value==='edit'? true: false}
                        //  style={{opacity: value==="edit"? 0.3:1}}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            department: e.target.value,
                          })
                        }
                      />
                    </div>
                    {isroleFilled && ismanagerFilled ? (
                      <ErrorPopup message="kindly fill * fields" />
                    ) : (
                      <></>
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button onClick={() => handleCancel()}>Cancel</Button>
                      <Button variant="contained" type="submit">
                        Save
                      </Button>
                    </div>
                  </form>
                ) : users.length !== 0 ? (
                  <div
                    style={{ width: "80%", overflow: "auto", marginTop: "3%" }}
                  >
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer sx={{ maxHeight: 440 }}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          sx={{
                            border: "1px solid #B3B3B3",
                            "& th, & td": { border: "1px solid #B3B3B3" },
                          }}
                        >
                          <TableHead sx={{ backgroundColor: "#173767" }}>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align="center"
                                  sx={{
                                    color: "#fff",
                                    backgroundColor: "#173767",
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {users
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, ind) => (
                                <TableRow
                                  key={row._id}
                                  disabled={!row.isActive ? true : false}
                                  style={{ opacity: !row.isActive ? 0.3 : 1 }}
                                >
                                  <TableCell align="center">{row.id}</TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {row.firstName + " " + row.lastName}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.email}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.dob}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.designation}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.department}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {row.role}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {row.manager}
                                  </TableCell>
                                  {/* <TableCell align="center">{row.status}</TableCell> */}
                                  <TableCell align="center">
                                    <EditButton
                                      user={row}
                                      setId={setId}
                                      setValue={setValue}
                                      setUserDetails={setUserDetails}
                                      setCreateUser={setCreateUser}
                                      isActive={row.isActive}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Switcher
                                      setSwitcherState={setSwitcherState}
                                      id={row._id}
                                      isActive={row.isActive}
                                      setToConfirm={setToConfirm}
                                      setValue={setValue}
                                      setId={setId}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  </div>
                ) : (
                  <p style={{ color: "red", fontSize: 22, margin: "auto" }}>
                    Users Not Found
                  </p>
                )}
              </Box>
            </div>
          ) : content === "calendar" ? (
            <div style={{ width: "100%" }}>
              <Calendar />
            </div>
          ) : content === "leave" ? (
            <div style={{ width: "100%" }}>
              <Leave />
            </div>
          ) : (
            <></>
          )
          // : content === "contacts" ? (
          //   <Box component="main" style={{width:"100%",padding:"20px"}}>
          //     <TabBarcont />
          //   </Box>
          // ) : (
          //   <Box component="main" sx={{ flexGrow: 1, p: 4 }}>
          //     <TabBarib />
          //   </Box>
          // )
        }
        {/* </div> */}
      </div>

      {toConfirm && (
        <ConfirmPrompt
          setToConfirm={setToConfirm}
          id={id}
          setId={setId}
          setCreateUser={setCreateUser}
          userDetails={userDetails}
          setUserDetails={setCreateUser}
          setValue={setValue}
          value={value}
        />
      )}
    </div>
  );
};

export default Admin;
