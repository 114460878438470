import * as React from "react";
import { styled, } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PushPinIcon from "@mui/icons-material/PushPin";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import "./Sidebarmenu.css";
import { useAppContext } from "../AppContext";

// import { useAppStore } from "../appStore";

const drawerWidth = 180;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: open ? "flex-end" : "center",
  padding: theme.spacing(0, 1),
  backgroundColor: "#fff",
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  backgroundColor: "#173767",
  Color: "#ffffff",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// const StyledIconButton = styled(IconButton)(({ theme }) => ({
//   marginRight: theme.spacing(1),
//   "&:hover": {
//     backgroundColor: "#E2A925",
//     borderRadius: "50%",
//   },
// }));

export default function Sidebar() {
  const { displayName, setContent, content, userInfo, toggleDisplayName } =
    useAppContext();
  // const theme = useTheme();
  const navigate = useNavigate();
  const open = displayName;

  const handleLogout = () => {
    localStorage.removeItem("KToken");
    navigate("/");
  };

  return (
    <div style={{ position: "relative" }}>
      <CssBaseline />

      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          size="large"
          edge="start"
          color="#000000"
          aria-label="open drawer"
          onClick={() => toggleDisplayName()}
          open={open}
        >
          {displayName ? (
            <FormatIndentDecreaseIcon sx={{ color: "#173767" }} />
          ) : (
            <FormatIndentIncreaseIcon sx={{ color: "#173767" }} />
          )}
        </DrawerHeader>
        <List
          sx={{ backgroundColor: "#173767", color: "#ffffff", height: "100%" }}
        >
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => setContent("dashboard")}
          >
            <ListItemButton
              sx={{
                minHeight: 40,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&:hover": {
                  backgroundColor: "#E2A925",
                  borderRadius: "20px 0 0 20px",
                },
                backgroundColor: content === "dashboard" ? "#62A841" : null,
                borderRadius: "20px 0 0 20px",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: "#ffffff",
                }}
              >
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          {userInfo.role !== "admin" ? (
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={() => setContent("task")}
            >
              <ListItemButton
                sx={{
                  minHeight: 40,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  "&:hover": {
                    backgroundColor: "#E2A925 !important",
                    borderRadius: "20px 0 0 20px",
                  },
                  backgroundColor: content === "task" ? "#62A841" : null,
                  borderRadius: "20px 0 0 20px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "#ffffff",
                  }}
                >
                  <PushPinIcon sx={{ transform: "rotate(45deg)" }} />
                </ListItemIcon>
                <ListItemText primary="Task" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ) : (
            <></>
          )}

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => setContent("calendar")}
          >
            <ListItemButton
              sx={{
                minHeight: 40,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&:hover": {
                  backgroundColor: "#E2A925",
                  borderRadius: "20px 0 0 20px",
                },
                backgroundColor: content === "calendar" ? "#62A841" : null,
                borderRadius: "20px 0 0 20px",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: "#ffffff",
                }}
              >
                <CalendarMonthIcon />
              </ListItemIcon>
              <ListItemText primary="Calendar" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          {/* <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => setContent('contacts')}
          >
            <ListItemButton
              sx={{
                minHeight: 40,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&:hover": {
                  backgroundColor: "#E2A925",
                  borderRadius: "20px 0 0 20px",
                },
                backgroundColor:content==='contacts'?"#62A841":null,
                borderRadius: "20px 0 0 20px"
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: "#ffffff",
                }}
              >
                <PeopleAltIcon />
              </ListItemIcon>
              <ListItemText primary="Contacts" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => setContent('invoice')}
          >
            <ListItemButton
              sx={{
                minHeight: 40,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&:hover": {
                  backgroundColor: "#E2A925",
                  borderRadius: "20px 0 0 20px",
                },
                backgroundColor:content==='invoice'?"#62A841":null,
                borderRadius: "20px 0 0 20px"
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: "#ffffff",
                }}
              >
                <ReceiptLongIcon />
              </ListItemIcon>
              <ListItemText
                primary="Invoice Bills"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem> */}

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => setContent("leave")}
          >
            <ListItemButton
              sx={{
                minHeight: 40,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&:hover": {
                  backgroundColor: "#E2A925",
                  borderRadius: "20px 0 0 20px",
                },
                backgroundColor: content === "leave" ? "#62A841" : null,
                borderRadius: content === "leave" ? "20px 0 0 20px" : null,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: "#ffffff",
                }}
              >
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Leave" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>

        <List sx={{ backgroundColor: "#173767", color: "#ffffff" }}>
          <hr className="text-secondary" />
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => handleLogout()}
          >
            <ListItemButton
              sx={{
                minHeight: 40,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&:hover": {
                  backgroundColor: "#E2A925",
                  borderRadius: "20px 0 0 20px",
                },
                borderRadius: "20px 0 0 20px",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: "#ffffff",
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}
