import React, { useState, useEffect } from "react";
import ".././page/CSS/Admin.css";
import axios from "axios";
import { Button,Box } from "@mui/material";
import { useAppContext } from "../AppContext";

const LeaveRequestForm = () => {
  const { userInfo, socket, setApplyLeave, setToggle,handleLeaveApproval,leaveApprovals} = useAppContext();
  const [errMsg, setErrMsg] = useState();
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leavesData, setleavesData] = useState({
    startDate: "",
    endDate: "",
    reason: "",
    manager: userInfo?.manager,
    timeOff: "fullday",
    type: "casual",
  });

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const today = getTodayDate().split("-");
      const year = today[0];
      const month = today[1] - 1 + "";
      const day = today[2];
      // console.log(typeof year, typeof month, typeof day);

      // console.log("....", {
      //   startDate: leavesData.startDate,
      //   endDate: leavesData.endDate,
      //   manager: leavesData.manager,
      //   timeOff: leavesData.timeOff,
      //   type: leavesData.type,
      //   reason: leavesData.reason,
      //   month,
      //   day,
      //   year,
      //   id: userInfo.id,
      //   user: userInfo._id,
      // });

      const response = await axios.post(`${process.env.REACT_APP_baseurl}/leave`, {
        startDate: leavesData.startDate,
        endDate: leavesData.endDate,
        manager: leavesData.manager,
        timeOff: leavesData.timeOff,
        type: leavesData.type,
        reason: leavesData.reason,
        month,
        day,
        year,
        id: userInfo.id,
        user: userInfo._id,
      });

      //this api call is not in use becuse of complexity
      // const updateToManager= await axios.put("http://localhost:5000/leave/updatetomanager",{id:userInfo.id,leaveId:leaveDetails._id});
      // console.log(leaveDetails)
      if (response) {
        const join = userInfo.id;
        const message = `${userInfo.name} request leave`;
        socket.emit("send message", { message, join });
        setleavesData({
          startDate: "",
          endDate: "",
          reason: "",
          manager: userInfo?.manager,
          timeOff: "fullday",
          type: "casual",
        });
        setApplyLeave(false);
        setToggle((val) => !val);
        alert("Leave request submitted successfully");
      }
    } catch (err) {
      // console.error(err.response?.data);
      // if(err.response.status===400){
      setShowErrMsg(true);
      setErrMsg(err.response?.data?.msg);
      // }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (errMsg) {
      const timer = setTimeout(() => {
        setShowErrMsg(false);
        setErrMsg("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showErrMsg,errMsg]);

  useEffect(()=>{if(!loading){handleLeaveApproval()}},[loading,leaveApprovals]);

  return (
    <>
      {showErrMsg && (
        <div
          style={{
            background: "red",
            width: "fit-content",
            padding: 8,
            borderRadius: 4,
            top: 10,
            right: 15,
            position: "absolute",
          }}
        >
          <p style={{ color: "white" }}>{errMsg}</p>
        </div>
      )}

      <div
        style={{
          textAlign: "center",
          width: "fit-content",
          margin: "auto",
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "0.5rem",
          overflowY: "auto",
          maxHeight: "100%",
        }}
      >
        <h2 style={{ margin: "10px 0", fontSize: "20px" }}>
          Leave Request Form
        </h2>
        {loading ? <Box style={{
          position: 'relative',
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          backgroundColor: "rgba(255,255,255,0.5)",
          width:"300px",
          height:"300px",
          
        }}>
          <img src="https://cdn.pixabay.com/animation/2023/05/02/04/29/04-29-06-428_512.gif" alt="animated loader" style={{width:"75px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}/>
        </Box> :<form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="startDate">
              Start Date<span className="text-[#FF0000]"> *</span>
            </label>
            <input
              id="startDate"
              type="date"
              value={leavesData.startDate}
              onChange={(e) =>
                setleavesData({ ...leavesData, startDate: e.target.value })
              }
              required
              min={getTodayDate()}
            />
          </div>
          <div className="form-group">
            <label htmlFor="endDate">
              End Date<span className="text-[#FF0000]"> *</span>
            </label>
            <input
              id="endDate"
              type="date"
              value={leavesData.endDate}
              onChange={(e) =>
                setleavesData({ ...leavesData, endDate: e.target.value })
              }
              required
              min={getTodayDate()}
            />
          </div>
          <div className="form-group">
            <label htmlFor="manager">
              Manager<span className="text-[#FF0000]"> *</span>
            </label>
            <input
              id="manager"
              type="text"
              value={leavesData.manager}
              style={{ opacity: 0.5 }}
              disabled
            />
          </div>
          <div className="form-group">
            <label htmlFor="timeOff">
              Time Off<span className="text-[#FF0000]"> *</span>
            </label>
            <select
              value={leavesData.timeOff}
              onChange={(e) =>
                setleavesData({ ...leavesData, timeOff: e.target.value })
              }
            >
              {/* <option value="Select Option">Select Option</option> */}
              <option value="fullday">Fullday</option>
              <option value="morningOff">Morning Off</option>
              <option value="afternoonOff">Afternoon Off</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="type">
              Type<span className="text-[#FF0000]"> *</span>
            </label>
            <select
              value={leavesData.type}
              onChange={(e) =>
                setleavesData({ ...leavesData, type: e.target.value })
              }
            >
              {/* <option value="Select Option">Select Option</option> */}
              <option value="casual">Casual</option>
              <option value="restricted">Restricted Leave</option>
              <option value="dayOff">Day Off</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="reason">
              Reason<span className="text-[#FF0000]"> *</span>
            </label>
            <textarea
              id="reason"
              value={leavesData.reason}
              onChange={(e) =>
                setleavesData({ ...leavesData, reason: e.target.value })
              }
              style={{
                border: "1px solid rgba(7,7,7,0.3)",
                borderRadius: "5px",
                minHeight: "50px",
                maxHeight: "125px",
                padding: "4px",
              }}
              required
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => setApplyLeave(false)}
              sx={{ textTransform: "capitalize", marginRight: "1rem" }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ textTransform: "capitalize", backgroundColor: "#613dc1" }}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </div>
        </form>}
      </div>
    </>
  );
};

export default LeaveRequestForm;
