import React, { useEffect, useState } from 'react'
// import { Select } from "antd";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ErrorPopup from '../Popup/ErrorPopup';
import axios from 'axios';
import LoginImage from "../images/intropic.jpeg";
import { setGlobalToken } from '../components/Authorization';
import {jwtDecode} from 'jwt-decode';
import { useAppContext } from '../AppContext';

const Login = () => {
  const { setUserInfo, userInfo } = useAppContext();
  const navigate = useNavigate();
  const [user, setUser] = useState({ email: "", password: "" });
  const [checkEmail, setCheckEmail] = useState(true);
  const [isFilled, setIsFilled] = useState(false);
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const isValidEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!user.email || !user.password) {
      setMessage("Kindly fill all the fields");
      setIsFilled(true);
      return;
    } else {
      setIsFilled(false);
    }
    loginApiCall();
  }

  const loginApiCall = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_baseurl}/login`, { email: user?.email, password: user?.password });
      if (response?.status === 200 || response?.status === 201) {
        localStorage.setItem("KToken", response.data.token);
        const token = response?.data?.token.split(' ')[1];
        const decodedToken = jwtDecode(token);
        if (decodedToken && decodedToken?.role) {
          setUserInfo(decodedToken);
          navigate(`/${decodedToken.role}`);
        }
        setUser({ ...user, password: "" });
        setGlobalToken();
      }
    } catch (err) {
      if (err?.response?.status === 400) {
        console.log(err.response.data.msg);
        setMessage(err.response.data.msg);
        setIsFilled(true);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const validateEmail = (e) => {
    setUser({ ...user, email: e?.target?.value });
    if (e?.target?.value && e?.target?.value.match(isValidEmail)) {
      setCheckEmail(false);
    } else {
      setCheckEmail(true);
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsFilled(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [isFilled, message, userInfo]);

  useEffect(() => {
    if (localStorage.getItem("KToken")) {
      const bToken = localStorage.getItem("KToken");
      const token = bToken.split(' ')[1];
      const decodedToken = jwtDecode(token);
      if (decodedToken && decodedToken?.role) {
        setUserInfo(decodedToken);
        navigate(`/${decodedToken.role}`);
        setGlobalToken();
      }
    } else {
      navigate("/");
    }
  }, []);

  return (
    <>
      <style>
        {`
         
          .logo{
          width:225px;
          }
          .form-container {
            flex: 1;
            padding: 30px;
            display:flex;
            flex-direction:column;
            flex-wrap:wrap;
            align-items:center;
          }
         
          .form-container h4 {
            text-align: center;
            font-size: 2.4rem;
           
          }
          .form-container form {
            margin:0;
          }
          .form-container form input {
            width: 100%;
            padding: 10px;
            font-size:20px;
            margin-bottom: 3px;
            border: 1px solid #ccc;
            border-radius: 5px;
          }
          .form-container form button {
            width: 100%;
            padding: 10px;
            background-color: #173767;
            color: #fff;
            border: none;
            border-radius: 5px;
            font-size: 1rem;
            cursor: pointer;
            transition: background-color 0.3s;
          }
          .form-container form button:disabled {
            background-color: rgba(17, 37, 67, 0.6);
          }
          .form-container form .link-no-underline {
            color: #173767;
            text-decoration: none;
            font-size:13px
          }
        
          @media (min-width: 769px) and (max-width: 1024px) {
           .logo{
          object-fit:contain;
          margin-left:-75px;
          }
          
            .form-container {
              padding: 22px;
            }
            .form-container h4 {
              font-size:2.0rem
            }
            
            .form-container form input {
              font-size:16px
            }
            .form-container form .link-no-underline {
              font-size:11px
            }
            .form-container form button{
              font-size:0.9rem;
              padding: 9px;
            }
          } 
          @media (max-width: 769px){
          .logo{
          object-fit:contain;
          margin-left:-75px;
          }
           
            .form-container {
              padding: 10px;
              justify-content:center

            }
            .form-container h4 {
              font-size:1.7rem;
              text-align:center
            }
            
            .form-container form input {
              font-size:14px
            }
            .form-container form .link-no-underline {
              font-size:11px
            }
            .form-container form button{
              font-size:0.8rem;
              padding: 8px;
            }
          }
        
        
        `}
      </style>
      <div className="wrapper">
          <img src="images/KRZLogo.png" className="logo" alt="logo" />
        <div className="form-container">
          <h4>Welcome Back!</h4>
          {isFilled && <ErrorPopup message={message} />}
          <form onSubmit={handleLogin}>
            <input
              type="email"
              placeholder="Enter Your Email"
              value={user.email}
              onChange={validateEmail}
              required
            />
            <input
              type="password"
              placeholder="Enter Your Password"
              value={user.password}
              onChange={(e) => setUser({ ...user, password: e.target.value })}
              required
            />
            {/* <div className="flex justify-end" >
              <Link to="/forgotpassword" className="link-no-underline" onClick={()=>{localStorage.removeItem("KToken");}}>
                Forgot Password?
              </Link>
            </div> */}
            <button type="submit" disabled={checkEmail}>
              {isLoading ? "Loading..." : "Login"}
            </button>
          </form>
        </div>
        <div className="image-container">
          <img src={LoginImage} alt="Login" />
        </div>
      </div>
    </>
  );
}

export default Login;
