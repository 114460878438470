import axios from "axios";
import {useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ErrorPopup from "../Popup/ErrorPopup";
import forgotPassword from "../images/forgotPassword.jpg";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {
  const inputRef = useRef(null);
  const [email, setEmail] = useState();
  const [checkEmail, setCheckEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState();

  const isValidEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const onSendcodeClick = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (!email) {
        setMessage("Kindly enter the email");
        setShow(true);
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_baseurl}/password-reset`,
        { email: email }
      );
      // if(response.status===200){
      //   navigate("/");
      // }
      if(response){
        toast.success(response?.data.msg, {
          position: "top-center",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        
      setEmail("");
      }
    } catch (err) {
      setMessage(err?.response?.data.msg);
      setShow(true);
      setEmail("");
    } finally {
      setIsLoading(false);
      navigate("/")
    }
  };
  const navigate = useNavigate();
  const handleBacktoLogin = () => {
    navigate("/");
    console.log("Back to login clicked");
  };
  // const backtoLogin = {
  //   color: "#173767",
  // };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShow(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [show, message]);

  useEffect(() => {
    if (!localStorage.getItem('KTOKEN')) {
      // KTOKEN is present
      navigate('/forgotpassword')
  }
    inputRef.current.focus();
  }, []);

  const validateEmail = (e) => {
    if (e?.target?.value && e?.target?.value.match(isValidEmail)) {
      setCheckEmail(false);
      setEmail(e.target.value);
    } else {
      setCheckEmail(true);
      setEmail(e.target.value);
    }
  };

  return (
    <>
      <style>
        {`
           .logo{
          width:225px;
          }
          .form-container .link-no-underline {
            color: #173767;
            text-decoration: none;
          }

          .password-container .form-groupss .resend-link{
            font-size:14px;
          }
            @media (min-width: 769px) and (max-width: 1024px) {
           .logo{
          object-fit:contain;
          margin-left:-75px;
          }
        }
          
          @media (max-width: 769px){
           .logo{
          object-fit:contain;
          margin-left:-75px;
          }
            .password-container .form-groupss .resend-link{
              font-size:13px;
            }
          }
        
         
        `}
      </style>
      <div className="wrapper">
      <img src="images/KRZLogo.png" className="logo" alt="logo" />
        {/* <div className="form-container"> */}
          
          <form className="password-container" onSubmit={onSendcodeClick}>
            <img
              alt=""
              src="/heroiconsoutlinefingerprint.svg"
            />
            <h4>Forgot Password?</h4>
            {show && <ErrorPopup message={message} />}

            <div className="form-groupss" >
              <label>
                Email<span className="text-danger"> *</span>
              </label>
              <br/>
              <input
                type="email"
                placeholder="Enter Your Email"
                onChange={(e) => validateEmail(e)}
                value={email}
                ref={inputRef}
                required
              />
            </div>
            <div className="form-groupss" style={{textAlign:"center"}}>
              Didn't receive the Email ?
              <span className="resend-link" style={{cursor:"pointer", marginLeft:"2px"}} onClick={onSendcodeClick}>
                {" Click to resend"}
              </span>
            </div>
            
            <button type="submit" disabled={checkEmail}>
              {!isLoading ? "Send" : "Loading..."}
            </button>
            
            <div className="form-groupss">
              <Link to="/" className="back-link" onClick={handleBacktoLogin}>
                <span>&#8701; </span> back to login
              </Link>
            </div>
            
          </form>
        {/* </div> */}
        <div className="image-container">
          <img src={forgotPassword} alt="Forgot Password" />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
