import React,{useState,useEffect} from 'react'
import { Switch } from '@mui/material';
import { toast } from 'react-toastify';

const Switcher = ({isActive,setSwitcherState,id,setToConfirm,setValue,setId}) => {
    const [deactivate,setDeactivate]=useState(isActive);
    
    const onHandleChange= async(e) =>{
        try{
        // setDeactivate(e.target.checked);
        setSwitcherState(false); 
        setToConfirm(true); 
        setValue("deactivate")
        setId(id)
        }
        catch(err){
            toast(err.response.data.msg, {
                type: "error",
                position: "top-right",
                autoClose: 3000,
              });
        }
    }

    useEffect(()=>setDeactivate(isActive),[isActive])
  return (
   <Switch checked={deactivate} disabled={!deactivate? true:false} onChange={(e)=> onHandleChange(e)} inputProps={{'aria-label':'controlled'}}/>
)
}

export default Switcher
