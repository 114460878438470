
import React,{useState,useEffect} from 'react'

const ErrorPopup = ({message}) => {
    const [isVisible,setIsVisible]=useState(true);
    // const timeoutRef=useRef(null);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setIsVisible(false);
        }, 2000);
        return () => clearTimeout(timeoutId);
      });

  return (
   isVisible && 
    <div style={{}}>
        <p style={{color:"red",marginBottom:"10px"}}>{message}</p>
    </div>
    
  )
}

export default ErrorPopup
