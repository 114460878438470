import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ConfirmPrompt({
  setToConfirm,
  id,
  setId,
  setCreateUser,
  userDetails,
  setUserDetails,
  setValue,
  value,
}) {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(true);
  const [confirmAction, setConfirmAction] = useState(value); // T
  // const handleOpenConfirmDialog = (action) => {
  //   setOpenConfirmDialog(true);
  //   setConfirmAction(action); // Set the action for confirmation
  // };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setToConfirm(false);
    setValue("");
    // setCreateUser(false);
  };

  const handleSave = async () => {
    try {
      const response =
        value !== "edit"
          ? await axios.post(`${process.env.REACT_APP_baseurl}/admin/create`, userDetails)
          : await axios.put(
              `${process.env.REACT_APP_baseurl}/admin/edit/${id}`,
              userDetails
            );
      // console.log(response.data.msg);
      // console.log("responseeee....", response.data);
      toast(response.data.msg, {
        type: "success",
        position: "top-right",
        autoClose: 3000,
      });
      if (value !== "edit" && response?.data?.managerId) {
        await axios.put(
          `${process.env.REACT_APP_baseurl}/admin/modify/${response.data.managerId}`,
          {
            empDbId: response.data.empDbId,
            id: response.data.id,
            name: response.data.name,
            leaves: [],
          }
        );
      }
    } catch (err) {
      console.log("post User....", err.response.data.msg);
      toast(err?.response?.data?.msg, {
        type: "error",
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setUserDetails({
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        role: "",
        manager: "",
        managerId: "",
        phoneNumber: "",
        dob: "",
      });
      setId("");
      setCreateUser(false);
      setToConfirm(false);
      setValue("");
    }
  };

  const handelDeactivate = async () => {
    try {
      // const response = await axios.delete(
      //   `http://localhost:5000/admin/delete/${id}`
      // );
      // console.log("delete.....", response.data);

      const response = await axios.put(
        `${process.env.REACT_APP_baseurl}/admin/active/${id}`,
        { isActive: false }
      );
      // console.log(response.data.msg);

      setToConfirm(false);
      setId("");
      toast(response?.data?.msg, {
        type: "success",
        position: "top-right",
        autoClose: 3000,
      });
      setValue("");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
      <DialogTitle>
        {confirmAction === "deactivate"
          ? "Confirm Deactivation"
          : confirmAction === "edit"
          ? "Confirm Edit"
          : "Confirm Save"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {confirmAction === "deactivate"
            ? "Are you sure you want to deactivate the user? This action cannot be undone."
            : `Are you sure you want to ${
                confirmAction === "edit" ? "edit" : "save"
              } the user?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
        <Button
          onClick={() => {
            value === "deactivate" ? handelDeactivate() : handleSave();
            handleCloseConfirmDialog();
          }}
        >
          {confirmAction === "deactivate"
            ? "Deactivate"
            : confirmAction === "edit"
            ? "Edit"
            : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmPrompt;
