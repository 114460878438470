import axios from 'axios';

// Function to set the token globally in Axios
function setGlobalToken() {
    // Retrieve the token from local storage
    const token = localStorage.getItem('KToken');

    // If token exists, set it in the Authorization header
    if (token) {
        axios.defaults.headers.common['authorization'] = `${token}`;
    }
}

// function getUserInfo(token){
//     try{
//         // const btoken=localStorage.getItem('token');
//         // const token=btoken.split(' ')[1];
//         return jwtDecode(token);
//     }catch (error){
//         return null;
//     }
// }

export {setGlobalToken};
