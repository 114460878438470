import React, { useCallback, useEffect, useState } from "react";
import { Grid, Box, List, ListItem, Typography } from "@mui/material";
import { useAppContext } from "../AppContext";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import LeaveRequestForm from "./LeaveRequestForm";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';


const Leave = () => {

  const {
    userInfo,
    setApplyLeave,
    applyLeave,
    handleLeaveApproval,
    leaveApprovals,
    setLeaveApprovals,
    setToggle,
    socket,
    updateLeave
  } = useAppContext();

  const [leaveType, setLeaveType] = useState("leaveType");
  const [leaveStatus, setLeaveStatus] = useState("leaveStatus");
  const [leaveRequestLoader, setLeaveRequestLoader] = useState(true);
  const [manageLeaveLoader, setManageLeaveLoader] = useState(true);
  // const [months,setMonths]= useState();
  const [leaveInfo, setLeaveInfo] = useState();
  const [approval, setApproval] = useState(false);
  const [leaveReason, setLeaveReason] = useState({ _id: "", reason: "" });
  const [joinedYear, setJoinedYear] = useState(
    parseInt(userInfo.joinedAt.split(" ")[2])
  );
  const [years, setYears] = useState(
    Array.from({ length: 5 }, (_, i) => joinedYear + i)
  );
  const [year, setYear] = useState(0);
  const [months, setMonths] = useState();
  const [monthlyLeaveCount, setMonthLeaveCount] = useState(0);
  const [restrictedLeaveCount, setRestrictedLeaveCount] = useState(0);
  const [dayOffCount, setDayOffCount] = useState(0);
  const [approvalFilter, setApprovalFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [approvalLoader, setApprovaLoader] = useState(false)

  const [restrictedLeave, setRestrictedLeave] = useState({
    date: "",
    leaveName: "",
  });
  const [setRL, setSetRL] = useState(false);
  const [rls, setRls] = useState();

  //Styles
  const styles = {
    container: {
      width: "100%",
      padding: 4,
      // margin: displayName ? "0 0 0 10rem" : "0 0 0 3rem",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      overflow: "auto",
      scrollbarWidth: "thin",
    },
    card: {
      backgroundColor: "white",
      padding: "0 10px",
      borderRadius: "5px",
      boxShadow: "black 3px 3px 7px",
      width: "100%", // Adjusts Box width to cover full area
      height: "100%", // Adjusts Box height to cover full area
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 468px)": {
        display: "none",
      },
    },
    listItem: {
      padding: "3px 10px",
      margin: "0",
      display: "flex",
      flexDirection: "column", alignItems: "flex-start",
    },
    typographyItem: {
      fontSize: "23px",
      width: "9rem",
      textTransform: "capitalize",
      "@media (max-width: 1120px)": {
        fontSize: "16px",
        width: "7rem",
      },
      "@media (max-width: 958px)": {
        fontSize: "16px",
        width: "6rem",
      },
      "@media (max-width: 768px)": {
        fontSize: "13px",
        width: "5rem",
      },
      "@media (max-width: 468px)": {
        width: "3rem",
      },
    },
    typographyDescription: {
      fontSize: "18px",
      color: "violet",
      "@media (max-width: 958px)": {
        fontSize: "13px",
      },
      "@media (max-width: 768px)": {
        fontSize: "10px",
      },
    },
    typographyHeading: {
      fontSize: "20px",
      "@media (max-width: 958px)": {
        fontSize: "18px",
      },
      "@media (max-width: 720px)": {
        fontSize: "16px",
      },
    },
    typographySize: {
      fontSize: "16px",
      textTransform: "capitalize",
      "@media (max-width: 958px)": {
        fontSize: "13px",
      },
      "@media (max-width: 720px)": {
        fontSize: "10px",
      },
    },
    separator: {
      color: "grey",
      fontSize: "45px",
      padding: "0 20px",

      "@media (max-width: 768px)": {
        fontSize: "35px",
      },
    },

    types_cont: {
      margin: "2rem 0 0 0",
      backgroundColor: "white",
      borderRadius: "5px",
      padding: "20px",
      width: "100%", // Adjusts Box width to cover full area
      height: "fit-content",
      boxShadow: "rgba(0,0,55,0.5) 3px 3px 7px",
      "@media (min-width: 480px) and (max-width: 1144px)": {
        width: "45%",
        marginTop: "0.5rem",
      },
    },
    name_month_cont: {
      display: "flex",
      flexDirection: "row",
      alginItem: "center",
      justifyContent: "space-between",
    },
    leave_cont: {
      width: "100%",
      height: "100%",
      borderRadius: "30px",
      padding: "0px",
      margin: "0px",
      fontSize: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    leave_outer_cont: {
      display: "flex",
      flexDirection: "column",
      height: "40%",
      justifyContent: "center",
      alignItems: "center",
    },

    apply_leave_cont: {
      backgroundColor: "white",
      // margin:"2rem 0rem 1rem 1.5rem",
      padding: "15px 0px",
      width: "100%",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      height: "fit-content",
      borderRadius: "6px",
      boxShadow: "rgba(0,0,55,0.5) 3px 3px 7px",
    },
    restricted_leave_cont: {
      backgroundColor: "white",
      margin: "0 1.5rem 0 0rem",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "fit-content",
      borderRadius: "6px",
      padding: "12px 8px",
      boxShadow: "rgba(0,0,55,0.5) 3px 3px 7px",
    },
    leave_request_cont: {
      backgroundColor: "white",
      margin: "2rem 0.7rem 0 0rem",
      padding: "20px 10px",
      width: "63.7%",
      boxShadow: "rgba(0,0,55,0.5) 3px 3px 7px",
      display: "flex",
      flexDirection: "column",
      borderRadius: "7px",
      "@media (min-width: 1640px) and (max-width: 1900px)": {
        width: "63.5%",
      },

      "@media (min-width: 1118px) and (max-width: 1640px)": {
        width: "62.3%",
      },

      "@media (min-width: 425px) and (max-width: 1144px)": {
        width: "100%",
        margin: "2rem 0",
      },
      "@media (min-width: 350px) and (max-width: 425px)": {
        width: "100%",
        margin: "1rem 0 0 0",
      },
    },
    leave_request_inner_cont: {
      display: "flex",
      flexDirection: "row",
      aligncontent: "center",
      alignItems: "center",
      justifyContent: "space-between",
    },
    manage_leave_cont: {
      backgroundColor: "white",
      margin: "0 0 0 0rem",
      padding: "7px 10px",
      width: "100%",
      borderRadius: "6px",
      // marginLeft:"rem",
      marginTop: "0.5rem",
      boxShadow: "rgba(0,0,55,0.5) 3px 3px 7px",
      "@media (min-width: 480px) and (max-width: 1144px)": {
        marginTop: "2.5rem",
      },
    },
    second_container: {
      width: "34%",
      marginLeft: "1.5rem",

      "@media (min-width: 350px) and (max-width: 1144px)": {
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        marginLeft: 0,
      },
    },
    scontainer_rl: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "1rem 0",
      "@media (min-width: 480px) and (max-width: 1144px)": {
        width: "45%",
        marginLeft: "10%",
        marginTop: "0.5rem",
      },
    },
    leave_table: {
      marginTop: "1rem",
      maxHeight: "335px",
      "@media (min-width: 480px) and (max-width: 780px)": {
        fontSize: "13px",
      },
    },
    muitable: {
      minWidth: 500,
      maxHeight: 500,
      overflow: "auto",

    },
  };


  // Inspired by the former Facebook spinners.
  // function createData(at, from, to, num, type, stat) {
  //   return { at, from, to, num, type, stat };
  // }


  const leaves = [
    { mon: "Jan" },
    { mon: "Feb" },
    { mon: "Mar" },
    { mon: "Apr" },
    { mon: "May" },
    { mon: "Jun" },
    { mon: "Jul" },
    { mon: "Aug" },
    { mon: "Sep" },
    { mon: "Oct" },
    { mon: "Nov" },
    { mon: "Dec" },
  ];
  const handleClose = () => {
    setApplyLeave(false);
  };
  const today = new Date(); // Create a new Date object for the current date
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear().toString();

  const getLeavesInfo = async () => {
    try {
      const leaves = await axios.post(`${process.env.REACT_APP_baseurl}/leave/getleave`, {
        id: userInfo?._id,
      });
      const RestrictedLeaves = await axios.get(
        `${process.env.REACT_APP_baseurl}/rl/fetch`
      );
      // console.log("leavess",leaves.data)
      setLeaveInfo(leaves?.data?.leaves);
      setRls(RestrictedLeaves?.data);
      // console.log("restricted leaves",RestrictedLeaves.data)
      // console.log(".......",leaves.data.length);
    } catch (err) {
      console.log(err);
    }
  }

  const getMonths = async () => {
    setManageLeaveLoader(true)
    try {
      const monthLeave = await axios.post(
        `${process.env.REACT_APP_baseurl}/leave/leaveinfo`,
        { id: userInfo._id, year: year === 0 ? currentYear : year }
      );

      setMonthLeaveCount(monthLeave?.data.casual);
      setDayOffCount(monthLeave?.data.dayOff);
      setRestrictedLeaveCount(monthLeave?.data.restricted);

      setMonths(monthLeave?.data.months);
    } catch (err) {
      console.log(err);
    } finally {
      setManageLeaveLoader(false)
    }
  };

  const handleRestrictedLeave = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      const rLeave = await axios.post(
        `${process.env.REACT_APP_baseurl}/rl/create`,
        restrictedLeave
      );

      if (rLeave) {
        setRestrictedLeave({ date: "", leaveName: "" });
        alert("Created Successfully");
      }
    } catch (err) {
      console.error("Error Occured", err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRLClose = () => {
    setSetRL(false);
  };

  const editRestrictedLeave = (data) => {
    setSetRL(true);
    // console.log(data)
    setRestrictedLeave({
      ...restrictedLeave,
      date: data.date,
      leaveName: data.leaveName,
    });
  };

  const handleLeaveRequest = async () => {
    try {
      setLeaveRequestLoader(true)
      const userInfoId = userInfo?._id;

      const response = await axios.post(
        `${process.env.REACT_APP_baseurl}/leave/getleave`,
        { id: userInfoId }
      );
      const leaves = response?.data?.leaves;

      const filterData = leaves.reduce((accumulator, leave) => {
        if (leaveType !== "leaveType" && leaveStatus !== "leaveStatus") {
          if (leave.type === leaveType && leave.status === leaveStatus) {
            accumulator.push(leave);
          }
        } else if (leaveType !== "leaveType") {
          if (leave.type === leaveType) {
            accumulator.push(leave);
          }
        } else if (leaveStatus !== "leaveStatus") {
          if (leave.status === leaveStatus) {
            accumulator.push(leave);
          }
        } else {
          accumulator.push(leave);
        }

        return accumulator;
      }, []);

      setLeaveInfo(filterData);
    } catch (error) {
      console.error("Error fetching leave data:", error);
    } finally {
      setLeaveRequestLoader(false)
    }
  };

  const handleLeaveApprovalFilter = async () => {
    try {
      const id = userInfo?.id;

      const approvals = await axios.post(
        `${process.env.REACT_APP_baseurl}/leave/notifyleave`,
        { id }
      );
      const leaves = approvals?.data;
      if (approvalFilter?.length !== 0) {
        const filteredData = leaves.filter(
          (leave) =>
            (approvalFilter
              ? leave.name.toLowerCase().includes(approvalFilter.toLowerCase())
              : true) ||
            (approvalFilter
              ? leave.id.toLowerCase().includes(approvalFilter.toLowerCase())
              : true)
        );
        setLeaveApprovals(filteredData);
      } else {
        setLeaveApprovals(leaves);
      }
    } catch (error) {
      console.error("Error fetching leave approval data:", error);
    }
  };

  const handleApproval = async (status) => {
    try {
      setApprovaLoader(true)
      const update = await axios.put(
        `${process.env.REACT_APP_baseurl}/leave/leaveApproval/${leaveReason?._id}`,
        { status }
      );
      if (update) {
        setLeaveReason({ _id: "", reason: "" });
        setApproval(false);
      }
    } catch (err) {
      console.error("Error as Occured", err.message);
    } finally {
      setApprovaLoader(false)
    }
  };

  function FacebookCircularProgress() {
    return (
      <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", height: "100px", justifyContent: "center", margin: "auto" }}>
        <CircularProgress />
      </div>

    );
  }

  const handleView = (_id, reason) => {
    console.log("claaaaaaa")
    setApproval(true);
    setLeaveReason({
      _id:_id,
      reason:reason,
    });
  }

  useEffect(() => {
    handleLeaveRequest();
    getLeavesInfo();
    getMonths();
  }, [])

  useEffect(() => {
    handleLeaveRequest();
  }, [leaveType, leaveStatus])

  useEffect(() => {
    getMonths();
  }, [year])

  useEffect(()=>{handleLeaveApprovalFilter()},[leaveApprovals])

  useEffect(()=>{handleLeaveApproval()},[approvalLoader])

  useEffect(() => {
    getLeavesInfo();
  }, [updateLeave])


  return (
    <>
     { userInfo.role === "admin" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 2,
            width: "100%",
            height: "auto",
            marginBottom: "3%",
            justifyContent: "flex-start",
            padding: "2%",
            flexWrap: "wrap",
            alignItems: "flex-start",
            alignContent: "start",
            "@media (min-width: 480px) and (max-width: 1415px)": {
              flexDirection: "column ",
              flexWrap: "nowrap",
              justifyContent: "flex-start",
            },
          }}
        >
          <Box sx={[styles.leave_request_cont, { width: "90% !important" }]}>
            {/* <Box sx={styles.leave_request_inner_cont}> */}
            <Typography sx={styles.typographyHeading}>
              Leave Approvals
            </Typography>
            <div
              style={{
                marginTop: "0.5rem",
                marginLeft: "auto",
                marginBottom: "0.5rem",
              }}
            >
              <FormControl
                variant="standard"
                sx={{ minWidth: 85 }}
              // disabled={leaveInfo?.length === 0 ? true : false}
              >
                <input
                  type="text"
                  placeholder="Search by Id or Name"
                  style={{
                    padding: "2%",
                    border: "2px solid #173767",
                    borderRadius: "10px",
                  }}
                  value={approvalFilter}
                  onChange={(e) => {handleLeaveApprovalFilter(e.target.value);setApprovalFilter(e.target.value)}}
                />
              </FormControl>
            </div>
            {/* </Box> */}

            {leaveApprovals?.length !== 0 ? (
              <TableContainer
                component={Paper}
                sx={{
                  ...styles.leave_table,
                  "@media (min-width: 480px) and (max-width: 1415px)": {
                    width: "100% !important",
                  },
                }}
              >
                <Table
                  sx={{
                    ...styles.muitable,
                    "@media (min-width: 480px) and (max-width: 1415px)": {
                      width: "100% !important",
                    },
                  }}
                  aria-label="caption table"
                >
                  <TableHead>
                    <TableRow sx={{ background: "#173767" }}>
                      <TableCell
                        sx={[styles.typographySize, { color: "#fff" }]}
                      >
                        Employee ID
                      </TableCell>
                      <TableCell
                        sx={[styles.typographySize, { color: "#fff" }]}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={[styles.typographySize, { color: "#fff" }]}
                      >
                        Apply Date
                      </TableCell>
                      <TableCell
                        sx={[styles.typographySize, { color: "#fff" }]}
                      >
                        Leave From
                      </TableCell>
                      <TableCell
                        sx={[styles.typographySize, { color: "#fff" }]}
                      >
                        Leave To
                      </TableCell>
                      <TableCell
                        sx={[styles.typographySize, { color: "#fff" }]}
                      >
                        No of Days
                      </TableCell>
                      <TableCell
                        sx={[styles.typographySize, { color: "#fff" }]}
                      >
                        Leave Type
                      </TableCell>

                      <TableCell
                        sx={[styles.typographySize, { color: "#fff" }]}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        sx={[styles.typographySize, { color: "#fff" }]}
                      >
                        Info
                      </TableCell>
                      {/* <TableCell>{""}</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leaveApprovals &&
                      leaveApprovals.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{ opacity: row.seen === true ? 0.8 : 1 }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={styles.typographySize}
                          >
                            {row.id}
                          </TableCell>
                          <TableCell
                            sx={[
                              styles.typographySize,
                              { textTransform: "capitalize" },
                            ]}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell sx={styles.typographySize}>
                            {row.appliedOn}
                          </TableCell>
                          <TableCell sx={styles.typographySize}>
                            {row.from}
                          </TableCell>
                          <TableCell sx={styles.typographySize}>
                            {row.to}
                          </TableCell>
                          <TableCell sx={styles.typographySize}>
                            {row.num_of_days}
                          </TableCell>
                          <TableCell
                            sx={[
                              styles.typographySize,
                              { textTransform: "capitalize" },
                            ]}
                          >
                            {row.type}
                          </TableCell>

                          <TableCell
                            sx={[
                              styles.typographySize,
                              {
                                color:
                                  row?.status === "approved"
                                    ? "green"
                                    : row?.status === "rejected"
                                      ? "red"
                                      : "purple",
                              },
                            ]}
                          >
                            {row.status}
                          </TableCell>
                          <TableCell
                            sx={[
                              styles.typographySize,
                              { textTransform: "capitalize" },
                            ]}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                textTransform: "capitalize",
                                display: "flex",
                                backgroundColor: "#173767",
                                position: "relative",
                                color: "#fff",
                                height: "30px",
                                borderRadius: "20px",
                                "&:hover": {
                                  backgroundColor: "#E2A925",
                                  color: "#fff",
                                },
                              }}
                              disabled={row.seen ? true : false}
                              onClick={() => 
                                handleView(row._id, row.reason)
                              }
                            >
                              View
                            </Button>
                          </TableCell>
                          {/* <TableCell sx={styles.typographySize}>
                          {<MoreVertIcon sx={{ cursor: "pointer" }} />}
                        </TableCell> */}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography sx={{ color: "red", margin: "auto" }}>
                No Data
              </Typography>
            )}
          </Box>
          {setRL && (
            <Modal
              open={setRL}
              onClose={() => handleRLClose()}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <form
                onSubmit={handleRestrictedLeave}
                style={{ margin: "5rem auto", backgroundColor: "white" }}
              >
                <div className="form-group">
                  <label htmlFor="startDate">
                    Date<span className="text-[#FF0000]"> *</span>
                  </label>
                  <input
                    id="startDate"
                    type="date"
                    value={restrictedLeave?.date}
                    onChange={(e) =>
                      setRestrictedLeave({
                        ...restrictedLeave,
                        date: e.target.value,
                      })
                    }
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="startDate">
                    Leave Info<span className="text-[#FF0000]"> *</span>
                  </label>
                  <input
                    id="startDate"
                    type="text"
                    value={restrictedLeave.leaveName}
                    onChange={(e) =>
                      setRestrictedLeave({
                        ...restrictedLeave,
                        leaveName: e.target.value,
                      })
                    }
                    required
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    onClick={() => setSetRL(false)}
                    sx={{ textTransform: "capitalize", marginRight: "1rem" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "#613dc1",
                    }}
                  >
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                </div>
              </form>
            </Modal>
          )}
          <Box
            sx={{
              width: "fit-content",
              height: "fit-content",
              display: "flex",
              flexDirection: "column",
              marginTop: "3%",
            }}
          >
            <Button
              variant="filled"
              sx={{
                display: "flex",
                backgroundColor: "#173767",
                position: "relative",
                color: "#fff",
                height: "30px",
                textTransform: "none",
                borderRadius: "20px",
                marginLeft: "auto",
                marginTop: "2%",
                marginBottom: "3%",

                "&:hover": {
                  backgroundColor: "#E2A925",
                  color: "#fff",
                },
              }}
              onClick={() => setSetRL(true)}
            >
              Create Restricted Leave
            </Button>

            <TableContainer sx={{ maxHeight: 440, width: "100%" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  border: "1px solid #B3B3B3",
                  "& th, & td": { border: "1px solid #B3B3B3" },
                }}
              >
                <TableHead sx={{ backgroundColor: "#173767" }}>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ color: "#fff", backgroundColor: "#173767" }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#fff", backgroundColor: "#173767" }}
                    >
                      Leave Info
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#fff", backgroundColor: "#173767" }}
                    >
                      Edit
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rls?.length !== 0 ?
                    rls?.map((row, ind) => (
                      <TableRow
                        key={row._id}
                        disabled={!row.isActive ? true : false}
                        style={{}}
                      >
                        <TableCell align="center">
                          {row?.date.split("-").reverse().join("-")}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.leaveName}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            variant="outlined"
                            onClick={() => editRestrictedLeave(row)}
                          >
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    )) : <TableRow sx={{ color: "red", textAlign: "center", width: "100%", padding: 3 }}>
                      No Data
                    </TableRow>}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      ) : !applyLeave ? (
        <Grid component="main" sx={styles.container}>
          {/* employee info */}
          <Box sx={styles.card}>
            <List sx={{ display: "flex", padding: 0, margin: 0 }}>
              <ListItem sx={styles.listItem}>
                <Typography sx={styles.typographyItem}>
                  {userInfo?.firstName + " " + userInfo.lastName}

                </Typography>

                <Typography sx={styles.typographyDescription}>
                  {userInfo?.designation}
                </Typography>

              </ListItem>
              <Typography sx={styles.separator}>/</Typography>
              <ListItem sx={styles.listItem}>
                <Typography sx={styles.typographyItem}>
                  Employee Id

                </Typography>
                <Typography sx={styles.typographyDescription}>
                  {userInfo.id}
                </Typography>
              </ListItem>
              <Typography sx={styles.separator}>/</Typography>
              <ListItem sx={styles.listItem}>
                <Typography sx={styles.typographyItem}>
                  Joining Date

                </Typography>
                <Typography sx={styles.typographyDescription}>
                  {userInfo.joinedAt}
                </Typography>
              </ListItem>
              <Typography sx={styles.separator}>/</Typography>
              <ListItem sx={styles.listItem}>
                <Typography sx={styles.typographyItem}>
                  Department

                </Typography>
                <Typography sx={styles.typographyDescription}>
                  {userInfo?.department}
                </Typography>
              </ListItem>
            </List>
          </Box>

          {userInfo.role === "manager" || userInfo.role === "admin" ? (
            <Box sx={[styles.leave_request_cont, { width: "100% !important" }]}>
              {/* <Box sx={styles.leave_request_inner_cont}> */}
              <Typography sx={styles.typographyHeading}>
                Leave Approvals
              </Typography>
              <div
                style={{
                  marginTop: "0.5rem",
                  marginLeft: "auto",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl
                  variant="standard"
                  sx={{ minWidth: 85 }}
                // disabled={leaveInfo?.length === 0 ? true : false}
                >
                  <input
                    type="text"
                    placeholder="Search by Id or Name"
                    style={{
                      padding: "2%",
                      border: "2px solid #173767",
                      borderRadius: "10px",
                    }}
                    value={approvalFilter}
                    onChange={(e) => {
                      handleLeaveApprovalFilter(e.target.value);
                      setApprovalFilter(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              {/* </Box> */}

              {leaveApprovals?.length !== 0 ? (
                <TableContainer
                  component={Paper}
                  sx={{
                    ...styles.leave_table,
                    "@media (min-width: 480px) and (max-width: 1415px)": {
                      width: "100% !important",
                    },
                  }}
                >
                  <Table
                    sx={{
                      ...styles.muitable,
                      "@media (min-width: 480px) and (max-width: 1415px)": {
                        width: "100% !important",
                      },
                    }}
                    aria-label="caption table"
                  >
                    <TableHead>
                      <TableRow sx={{ background: "#173767" }}>
                        <TableCell
                          sx={[styles.typographySize, { color: "#fff" }]}
                        >
                          Employee ID
                        </TableCell>
                        <TableCell
                          sx={[styles.typographySize, { color: "#fff" }]}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={[styles.typographySize, { color: "#fff" }]}
                        >
                          Apply Date
                        </TableCell>
                        <TableCell
                          sx={[styles.typographySize, { color: "#fff" }]}
                        >
                          Leave From
                        </TableCell>
                        <TableCell
                          sx={[styles.typographySize, { color: "#fff" }]}
                        >
                          Leave To
                        </TableCell>
                        <TableCell
                          sx={[styles.typographySize, { color: "#fff" }]}
                        >
                          No of Days
                        </TableCell>
                        <TableCell
                          sx={[styles.typographySize, { color: "#fff" }]}
                        >
                          Leave Type
                        </TableCell>

                        <TableCell
                          sx={[styles.typographySize, { color: "#fff" }]}
                        >
                          Status
                        </TableCell>
                        <TableCell
                          sx={[styles.typographySize, { color: "#fff" }]}
                        >
                          Info
                        </TableCell>
                        {/* <TableCell>{""}</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {leaveApprovals &&
                        leaveApprovals.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{ opacity: row.seen === true ? 0.6 : 1 }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={styles.typographySize}
                            >
                              {row.id}
                            </TableCell>
                            <TableCell
                              sx={[
                                styles.typographySize,
                                { textTransform: "capitalize" },
                              ]}
                            >
                              {row.name}
                            </TableCell>
                            <TableCell sx={styles.typographySize}>
                              {row.appliedOn}
                            </TableCell>
                            <TableCell sx={styles.typographySize}>
                              {row.from}
                            </TableCell>
                            <TableCell sx={styles.typographySize}>
                              {row.to}
                            </TableCell>
                            <TableCell sx={styles.typographySize}>
                              {row.num_of_days}
                            </TableCell>
                            <TableCell
                              sx={[
                                styles.typographySize,
                                { textTransform: "capitalize" },
                              ]}
                            >
                              {row.type}
                            </TableCell>

                            <TableCell
                              sx={[
                                styles.typographySize,
                                {
                                  color:
                                    row?.status === "approved"
                                      ? "green"
                                      : row?.status === "rejected"
                                        ? "red"
                                        : "purple",
                                },
                              ]}
                            >
                              {row.status}
                            </TableCell>
                            <TableCell
                              sx={[
                                styles.typographySize,
                                { textTransform: "capitalize" },
                              ]}
                            >
                              <Button
                                 variant="contained"
                                 sx={{
                                   textTransform: "capitalize",
                                   display: "flex",
                                   backgroundColor: "#173767",
                                   position: "relative",
                                   color: "#fff",
                                   height: "30px",
                                   borderRadius: "20px",
                                   "&:hover": {
                                     backgroundColor: "#E2A925",
                                     color: "#fff",
                                   },
                                 }}
                                 disabled={row.seen ? true : false}
                                 onClick={() => 
                                   handleView(row._id, row.reason)
                                 }
                              >
                                View
                              </Button>
                            </TableCell>
                            {/* <TableCell sx={styles.typographySize}>
                                {<MoreVertIcon sx={{ cursor: "pointer" }} />}
                              </TableCell> */}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography sx={{ color: "red", margin: "auto" }}>
                  No Data
                </Typography>
              )}
            </Box>
          ) : (
            <></>
          )}

          {/* Leave Request */}
          <Box sx={styles.leave_request_cont}>
            {/* <Box sx={styles.leave_request_inner_cont}> */}
            <Typography sx={styles.typographyHeading}>Leave Request</Typography>
            <div
              style={{
                marginTop: "0.5rem",
                marginLeft: "auto",
                marginBottom: "0.5rem",
              }}
            >
              <FormControl
                variant="standard"
                sx={{ minWidth: 85 }}
              // disabled={leaveInfo?.length === 0 ? true : false}
              >
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={leaveType}
                  sx={styles.typographySize}
                  onChange={(e) => {
                    setLeaveType(e.target.value);
                    handleLeaveRequest();
                  }}
                >
                  <MenuItem value="leaveType" sx={styles.typographySize}>
                    <em>Leave Type</em>
                  </MenuItem>
                  <MenuItem value={"casual"} sx={styles.typographySize}>
                    Casual
                  </MenuItem>
                  <MenuItem value={"restricted"} sx={styles.typographySize}>
                    Restricted
                  </MenuItem>
                  <MenuItem value={"dayOff"} sx={styles.typographySize}>
                    DayOff
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                sx={{ marginLeft: "2rem", minWidth: 85 }}
              // disabled={leaveInfo?.length === 0 ? true : false}
              >
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={leaveStatus}
                  sx={styles.typographySize}
                  onChange={(e) => {
                    setLeaveStatus(e.target.value);
                    handleLeaveRequest();
                  }}
                >
                  <MenuItem value="leaveStatus" sx={styles.typographySize}>
                    <em>Leave Status</em>
                  </MenuItem>
                  <MenuItem value={"approved"} sx={styles.typographySize}>
                    Approved
                  </MenuItem>
                  <MenuItem value={"rejected"} sx={styles.typographySize}>
                    Rejected
                  </MenuItem>
                  <MenuItem value={"pending"} sx={styles.typographySize}>
                    Pending
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* </Box> */}
            {/* loader */}
            {leaveRequestLoader ? <FacebookCircularProgress /> :
              leaveInfo?.length !== 0 ? (
                <TableContainer component={Paper} sx={styles.leave_table}>
                  <Table sx={styles.muitable} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={styles.typographySize}>
                          Apply Date
                        </TableCell>
                        <TableCell sx={styles.typographySize}>
                          Leave From
                        </TableCell>
                        <TableCell sx={styles.typographySize}>Leave To</TableCell>
                        <TableCell sx={styles.typographySize}>
                          No of Days
                        </TableCell>
                        <TableCell sx={styles.typographySize}>
                          Leave Type
                        </TableCell>
                        <TableCell sx={styles.typographySize}>Status</TableCell>
                        {/* <TableCell>{""}</TableCell> */}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {leaveInfo &&
                        leaveInfo?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={styles.typographySize}
                            >
                              {row.appliedOn}
                            </TableCell>
                            <TableCell sx={styles.typographySize}>
                              {row.from}
                            </TableCell>
                            <TableCell sx={styles.typographySize}>
                              {row.to}
                            </TableCell>
                            <TableCell sx={styles.typographySize}>
                              {row.num_of_days}
                            </TableCell>
                            <TableCell
                              sx={[
                                styles.typographySize,
                                { textTransform: "capitalize" },
                              ]}
                            >
                              {row.type}
                            </TableCell>
                            <TableCell
                              sx={[
                                styles.typographySize,
                                {
                                  color:
                                    row.status === "approved"
                                      ? "green"
                                      : row.status === "rejected"
                                        ? "red"
                                        : "purple",
                                },
                              ]}
                            >
                              {row.status}
                            </TableCell>
                            {/* <TableCell sx={styles.typographySize}>
                            {<MoreVertIcon sx={{ cursor: "pointer" }} />}
                          </TableCell> */}
                          </TableRow>
                        ))}
                    </TableBody>

                  </Table>
                </TableContainer>
              ) : (
                <Typography sx={{ color: "red", margin: "auto" }}>
                  No Data
                </Typography>
              )}

          </Box>

          <Box sx={styles.second_container}>
            {/* Leaves */}

            <Box sx={styles.types_cont}>
              <div style={styles.name_month_cont}>
                <Typography sx={styles.typographyHeading}>{leaves[currentMonth].mon}</Typography>
                {/* <Typography sx={styles.typographyHeading}>Month</Typography> */}
              </div>
              <List
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  margin: 0,
                }}
              >
                <ListItem sx={styles.leave_outer_cont}>
                  <Box sx={styles.leave_cont} backgroundColor="#bbd0ff">
                    <Typography sx={{ padding: "6px 0px" }}>
                      {monthlyLeaveCount}/2
                    </Typography>
                  </Box>
                  <Typography sx={styles.typographySize}>
                    Casual Leave
                  </Typography>
                </ListItem>
                <ListItem sx={styles.leave_outer_cont}>
                  <Box sx={styles.leave_cont} backgroundColor="#c8b6ff">
                    <Typography sx={{ padding: "6px 0px" }}>
                      {dayOffCount}/1
                    </Typography>
                  </Box>
                  <Typography sx={styles.typographySize}>Day Off</Typography>
                </ListItem>
              </List>
            </Box>

            <Box sx={styles.scontainer_rl}>
              <Box sx={styles.restricted_leave_cont}>
                <Typography sx={styles.typographyHeading}>
                  Restricted Leave
                </Typography>
                <Box sx={styles.leave_cont} backgroundColor="#c2c9d6">
                  <Typography sx={{ padding: "6px 0px" }}>
                    {restrictedLeaveCount}/3
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.apply_leave_cont}>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#613dc1",
                  }}
                  onClick={() => setApplyLeave(true)}
                >
                  Apply Leave
                </Button>
              </Box>
            </Box>
            <Box sx={styles.manage_leave_cont}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={styles.typographyHeading}>
                  Manage Leave
                </Typography>
                <FormControl
                  sx={{ marginTop: 1, marginBottom: 1, minWidth: 80 }}
                  size="small"
                >
                  <InputLabel id="demo-select-small-label">Year</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={year === 0 ? currentYear : year}
                    onChange={(e) => setYear(e.target.value)}
                    label="Year"
                  >
                    {years &&
                      years.map((year, ind) => (
                        <MenuItem
                          value={year}
                          key={year}
                          selected={currentYear === year ? true : false}
                        >
                          {year}
                        </MenuItem>
                      ))}

                    {/* <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>
              </div>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  padding: "5px",
                  justifyContent: "center",
                }}
              >
                {manageLeaveLoader ? <FacebookCircularProgress /> : months &&
                  leaves.map((val, ind) => (
                    <div key={ind}>
                      <Box
                        sx={{
                          width: "fit-content",
                          padding: "2px",
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          alignItems: "flex-end",
                          border: "2px solid #415a77",
                          margin: "0.2rem 0.30rem",
                          borderRadius: "5px",
                        }}
                      >
                        <Typography
                          sx={[styles.typographySize, { width: "30px" }]}
                        >
                          {val.mon}
                        </Typography>
                        <Typography
                          sx={[styles.typographySize, { width: "20px" }]}
                        >
                          {months[ind]}
                        </Typography>
                      </Box>
                    </div>
                  ))}
              </Box>
            </Box>
          </Box>

        </Grid>
      ) : !approvalLoader ?
        <Modal
          open={applyLeave}
          onClose={() => handleClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{ margin: "3rem auto", height: "80%", scrollbarWidth: "thin" }}
          >
            <LeaveRequestForm />
          </Box>
        </Modal> : <Box style={{
          position: 'relative',
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          backgroundColor: "rgba(255,255,255,0.5)",
          height: "100vh",
          
        }}>
          <img src="https://cdn.pixabay.com/animation/2023/05/02/04/29/04-29-06-428_512.gif" alt="animated loader" style={{width:"75px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}/>
        </Box>   }
      {approval&&
        <Modal
          open={approval}
          onClose={() => setApproval(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{display:"flex",
            justifyContent:"center",
            alignItems:"center",
            
            width: "100%",
           }}
        > 
          <Box
            style={{
              display:"flex",
              justifyContent:"center",
              flexDirection:"column",
              backgroundColor: "white",
              width: "300px",
              padding: "2%",
              borderRadius: "4px",
              position:"relative"
            }}
          >
            <img src="https://static.vecteezy.com/system/resources/previews/018/886/521/non_2x/signs-close-icon-png.png" alt="close icon" style={{position:"absolute",cursor:"pointer", top:"-15px",right:"-15px",width:"30px"}} onClick={()=> setApproval(false)}/>
            <label>Reason</label>
            <input
              type="text"
              readOnly
              value={leaveReason.reason}
              style={{
                border: "2px solid #173767",
                borderRadius: "4px",
                padding: "4px",
                margin: "2% 0",
                width: "100%",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "4%",
              }}
            >
              <Button
                sx={{
                  background: "red",
                  color: "white",
                  textTransform: "capitalize",
                }}
                variant="contained"
                onClick={() => handleApproval("rejected")}
              >
                Reject
              </Button>
              <Button
                sx={{
                  background: "green",
                  color: "white",
                  textTransform: "capitalize",
                }}
                variant="contained"
                onClick={() => handleApproval("approved")}
              >
                Approve
              </Button>
            </Box>
          </Box>
          
        </Modal>}
            
     
    </>
  );
};

export default Leave;
