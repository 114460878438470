import * as React from "react";
import { useState,useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ListItemText from "@mui/material/ListItemText";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MuiAppBar from "@mui/material/AppBar";
import NotificationSound from "../assets/sound/level-up-191997.mp3";
import Profile from "../Popup/Profile";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../AppContext";

// const AppBar = styled(MuiAppBar)(({ theme }) => ({
//   // zIndex: theme.zIndex.drawer + 1,
//   backgroundColor: "#ffffff",
//   height: "60px",
// }));

// const StyledIconButton = styled(IconButton)(({ theme }) => ({
//   marginRight: theme.spacing(1),
//   "&:hover": {
//     backgroundColor: "#E2A925",
//     borderRadius: "50%",
//   },
// }));

const Logo = styled("img")(({ theme }) => ({
  width: "120px",
  height: "50px",
  // marginTop: theme.spacing(1),
  marginRight: theme.spacing(5),
  borderRadius: "4px",
}));

const WelcomeText = styled(Typography)(({ theme }) => ({
  color: "#000000",
  textTransform: "capitalize",
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "block",
  },
}));

// const FlexGrow = styled(Box)({
//   flexGrow: 1,
// });



const ProfileText = styled(ListItemText)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: "#173767",
  textTransform: "capitalize",
}));

const MobileDisplayNone = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const MobileDisplayFlex = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

export default function Navbar() {
  const [profilePopupOpen, setProfilePopupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const {
   
    userInfo,
    audioplayer,
    
    setContent,
    leaveNotification,
    toggle
  } = useAppContext();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const openProfilePopup = () => {
    setProfilePopupOpen(true);
    handleMenuClose();
    handleMobileMenuClose();
  };

  const closeProfilePopup = () => {
    setProfilePopupOpen(false);
  };

  const handleProfileDetails = (profileDetails) => {
    openProfilePopup(profileDetails);
  };

  // const handleLogout = () => {
  //   localStorage.removeItem("KToken");
  //   navigate("/");
  // };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={openProfilePopup}>Profile</MenuItem>
      {/* <MenuItem onClick={handleLogout}>Logout</MenuItem> */}
    </Menu>
  );
  // useEffect(()=>{},[toggle])
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 5 new notifications"
          color="#000000"
          onClick={() => setContent("leave")}
        >
          <Badge
            badgeContent={
              leaveNotification?.length > 0 ? leaveNotification?.length : 0
            }
            color="error"
          >
            <NotificationsIcon sx={{ color: "#173767" }} />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="#000000"
        >
          <AccountCircleIcon sx={{ color: "#173767" }} />
          <ProfileText
            primary={userInfo?.firstName ? userInfo?.firstName : "User"}
          />
        </IconButton>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          boxShadow:
            " 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          padding: "0 14px !important",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* <StyledIconButton
         size="large"
            edge="start"
            color="#000000"
            aria-label="open drawer"
            onClick={() => toggleDisplayName()}
            
          >
            {displayName ? (
              <FormatIndentDecreaseIcon sx={{ color: "#173767" }} />
            ) : (
              <FormatIndentIncreaseIcon sx={{ color: "#173767" }} />
            )}
         </StyledIconButton> */}
          <Logo src="KRZLogo.png" alt="logo" />
          <WelcomeText variant="h6" noWrap component="div">
            Welcome{" "}
            {`${userInfo.firstName ? userInfo.firstName : "..."} ${
              userInfo?.lastName ? userInfo.lastName : ""
            }`}
          </WelcomeText>
        </div>

        <div>
          <MobileDisplayNone>
            <audio src={NotificationSound} ref={audioplayer} />
            <IconButton
              size="large"
              aria-label="show 5 new notifications"
              color="#000000"
              onClick={() => setContent("leave")}
            >
              <Badge
                badgeContent={
                  leaveNotification?.length > 0 ? leaveNotification?.length : 0
                }
                color="error"
              >
                <NotificationsIcon sx={{ color: "#173767" }} />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="#000000"
            >
              <AccountCircleIcon sx={{ color: "#173767" }} />
              <ProfileText primary={userInfo.firstName} />
            </IconButton>
          </MobileDisplayNone>
          <MobileDisplayFlex>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="#000000"
            >
              <MoreIcon />
            </IconButton>
          </MobileDisplayFlex>
        </div>
      </Toolbar>
      <Profile
        isOpen={profilePopupOpen}
        onClose={closeProfilePopup}
        onProfilePage={handleProfileDetails}
      />
      {renderMobileMenu}
      {renderMenu}
    </>
  );
}

