import React, { useCallback, useRef, useState } from "react";
import AppContext from "./AppContext";
import { io } from "socket.io-client";
import axios from "axios";
const socket =io.connect(`${process.env.REACT_APP_socketurl}`) ;

const AppProvider = ({ children }) => {
  const audioplayer = useRef(null);
  const [userInfo, setUserInfo] = useState();
  const [displayName, setDisplyName] = useState(false);
  const [content, setContent] = useState("dashboard");
  const [toggle, setToggle] = useState(false);
  const [applyLeave, setApplyLeave] = useState(false);
  const [receivedMessage, setReceivedMessage] = useState("");
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [leaveApprovals, setLeaveApprovals] = useState();
  const [leaveNotification, setLeaveNotification] = useState();

  const toggleDisplayName = () => setDisplyName(!displayName);

  function playAudio() {
    if (audioplayer.current && typeof audioplayer?.current?.play === "function") {
      audioplayer?.current?.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    } else {
      console.error("audioplayer.current is null or play is not a function");
    }
  }

  const handleLeaveApproval = async () => {
   try{
    if (userInfo?.id) {
      const id = userInfo?.id;
      const approvals = await axios.post(
        `${process.env.REACT_APP_baseurl}/leave/notifyleave`,
        { id }
      );
      const notify = approvals?.data.filter((leave) => !leave?.seen);
      setLeaveNotification(notify);
      setLeaveApprovals(approvals?.data);
      
    }
   }catch(err){
    console.error(err.message)
  }
  }

  return (
    <AppContext.Provider
      value={{
        userInfo,
        screenSize,
        setScreenSize,
        setUserInfo,
        toggleDisplayName,
        displayName,
        content,
        setContent,
        socket,
        audioplayer,
        playAudio,
        setReceivedMessage,
        setToggle,
        toggle,
        receivedMessage,
        applyLeave,
        setApplyLeave,
        handleLeaveApproval,
        leaveApprovals,
        leaveNotification,
        setLeaveApprovals,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
