import React,{useEffect, useState} from "react";
import { Button } from "@mui/material";

const EditButton = ({user,setId,setCreateUser,setUserDetails,setValue,isActive}) => {
    const [edit,setEdit]= useState(isActive);
    const handleEdit = (user) => {
        setId(user._id);
        setValue('edit')
        setUserDetails(user);
        setCreateUser(true);
      };

      useEffect(()=>setEdit(isActive),[isActive]);
  return (
    <Button
      onClick={() => handleEdit(user)}
      disabled={!edit ? true : false}
    >
      Edit
    </Button>
  );
};

export default EditButton;
