import React from 'react';
import { Link } from 'react-router-dom';
import '../CSS/Error400Page.css'; // Import your CSS styles for this component

const Error400Page = () => {
  return (
    <div className="error-400-page">
      <h1>400 - Bad Request</h1>
      <p>Sorry, it looks like there was a problem with your request.</p>
      <p>Please check the URL or go back to the previous page.</p>
      <Link to="/">Go back to Home</Link>
    </div>
  );
};

export default Error400Page;
