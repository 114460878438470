import {useContext,createContext} from "react";


//create a context

const AppContext=createContext();


//Export a custom hook to use the context
export const useAppContext=()=>useContext(AppContext);
export default AppContext;