import React, { useCallback,useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DashboardC from "../components/DashboardC";
import { useAppContext } from "../AppContext";
import Tasktable from '../table/Tasktable';
import TabBarcont from "../components/TabBarcont";
import Calendar from "../components/Calendar";
import TabBarib from "../components/TabBarib";
import Leave from "../components/Leave";

const Manager = () => {
  const {content}=useAppContext();
  // const [activity,setActivity]= useState(false);

  return (
    <div
    style={{
      display: "flex",
      flexDirection: "row",
      height: "100vh",
      // position: "relative",
    }}
  >
    <Sidebar />
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width:"100%",
        alignItems:"center",
        
      }}
    >
      <Navbar />

          {content==='dashboard'?<Box sx={{width:"100%"}}>  <Button
              variant="filled"
              sx={{
                display:"flex",
                backgroundColor: "#173767",
                position: "relative",
                color: "#fff",
                height: "30px",
                textTransform: "none",
                borderRadius: "20px",
                marginLeft:"auto",
                marginTop:"2%",
                marginBottom:"1%",
                marginRight:"1%",
                "&:hover": {
                  backgroundColor: "#E2A925",
                  color: "#fff",
                },
              }}
              // onClick={() => setActivity(true)}
            >
              Candidates Daily Activity
            </Button>
            <DashboardC/>
            </Box>: content==='task'? <Box component="main" sx={{ width:"100%"}}>
            <Tasktable/>
  </Box>:content==='calendar'? <Box sx={{width:"100%"}}><Calendar/></Box>: content==='contacts'? <Box component="main" >
  <TabBarcont/>
  </Box>: content === 'leave' ? <div style={{width:"100%"}}><Leave/></div>:<Box component="main" >
  <TabBarib/>
  </Box>}
      </div>
      </div>
  );
};

export default Manager;


// {content==='dashboard'?<DashboardC/>: content==='task'? <Box component="main" sx={{ flexGrow: 1, pl:2}}>
// <Button
//     variant="filled"
//     sx={{
//       backgroundColor: "#173767",
//       position: "relative",
//       color: "#fff",
//       height: "30px",
//       textTransform: "none",
//       borderRadius: "20px",
//       "&:hover": {
//         backgroundColor: "#E2A925",
//         color: "#fff",
//       },
//     }}
//     onClick={() => setActivity(true)}
//   >
//     Candidates Daily Activity htrh
//   </Button>
//   <Tasktable/>
//   </Box>:content==='calendar'? <Calendar/>: content==='contacts'? <Box component="main" sx={{ flexGrow: 1, p:4}}>
//   <TabBarcont/>
//   </Box>: content === 'leave' ? <Leave/>:<Box component="main" sx={{ flexGrow: 1, p:4}}>
//   <TabBarib/>
//   </Box>}